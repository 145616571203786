export const logo = "https://firebasestorage.googleapis.com/v0/b/invoicing-pro-shubham-saw.appspot.com/o/invoice12.png?alt=media&token=19d83d50-ea52-4965-a25e-dbfe7117c4f3"

export function  removeSpaces(str){
    return str.replaceAll(/\s/g,'')
}

export function shuffle(array) {
    let currentIndex = array.length,  randomIndex;
  
    // While there remain elements to shuffle...
    while (currentIndex != 0) {
  
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
  }

  export function ExcelDateToJSDate(serial) {
    var utc_days  = Math.floor(serial - 25569);
    var utc_value = utc_days * 86400;                                        
    var date_info = new Date(utc_value * 1000);
 
    var fractional_day = serial - Math.floor(serial) + 0.0000001;
 
    var total_seconds = Math.floor(86400 * fractional_day);
 
    var seconds = total_seconds % 60;
 
    total_seconds -= seconds;
 
    var hours = Math.floor(total_seconds / (60 * 60));
    var minutes = Math.floor(total_seconds / 60) % 60;
 
    return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
 }

 export function randomIntFromInterval(min, max) { // min and max included 
    // find diff
    let difference = max - min;

    // generate random number 
    let rand = Math.random();

    // multiply with difference 
    rand = Math.floor( rand * difference);

    // add with min value 
    rand = rand + min;
   
    return rand;

}

export function GetUnitsFromInvoiceArray(invoicearray, currentItemId){

  let units =0

  for(let i=0; i< invoicearray.length; i++){
    if(invoicearray[i]['itemid'] == currentItemId){
      units =   parseFloat(invoicearray[i]['itemquantity'])
      break;
    }
  }

  return units

}

export function GetPriceFromInvoiceArray(invoicearray, currentItemId){

  let price =0

  for(let i=0; i< invoicearray.length; i++){
    if(invoicearray[i]['itemid'] == currentItemId){
      price =   parseFloat(invoicearray[i]['itemprice'])
      break;
    }
  }

  return price

}

export function toTitleCase(str) {
  if(str!="" && typeof(str)=="string"){
  return str.replace(
    /\w\S*/g,
    function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );} else return str
}