import "firebase/auth";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";



const firebaseConfig = {
  apiKey: "AIzaSyCzHf-p7bq7IKbmHLfmWZEsmp-5VUMsHIo",
  authDomain: "invoicing-pro-shubham-saw.firebaseapp.com",
  //databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: "invoicing-pro-shubham-saw",
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
  };

  firebase.initializeApp(firebaseConfig);

  
export default firebase;
