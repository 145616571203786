import {Navbar, Nav, Form, Button, Table, Container, Modal} from 'react-bootstrap';
import '../App.css';
import * as XLSX from "xlsx";
import db from '../firebase';
import React from 'react'
import { useState, useEffect,useContext } from 'react';
import { Fragment } from "react";
import dateformat from "dateformat"
import { useHistory } from 'react-router';
import axios from 'axios';
import Progress from 'react-progressbar';
import LoaderUi from "../LoaderUi"
 import firebase from '../firebase';
 import * as Loader from 'react-loader-spinner'
import { removeSpaces } from '../Common/CommonVarFunc';

function ImportItems({setModalOpen}) {
  const [users, setUsers] = useState([]);
  const [show, setShow] = useState(false);
  const [headers, setHeaders] = useState([]);
  const [rows, setRows] = useState([]);
  const [completedPart, setCompletedPart] = useState(0)
  const [showProcessingPart, setShowProcessingPart] = useState(false)
  const [totalMessages, setTotalMessages] = useState(0)
  const [sentMessages, setSentMessages] = useState(0)
  const [passcode, setPasscode] = useState("")
  const history = useHistory()
  const[message, setMessage] = useState("")
  const[sendMesaage, setSendMessage] = useState("")
 
  // useEffect(()=>{
  // //   axios.get("http://us-central1-bharatkojanofinale.cloudfunctions.net/GetAuthPasscode?").then((res)=>{
  // //     setPasscode(res.data)
  // //   })
  // // },[])

  function DateTimeString(date){
    
    let mon = date.getMonth()
    let dat = date.getDate()
    let ye = date.getFullYear()
  let min = date.getMinutes()
  let sec = date.getSeconds() 
  let hh = date.getHours()
  
  let finalDate = `${dat}/${mon}/${ye} ${hh}:${min}:${sec}`
  return finalDate
   
  }
  const handleClose = () => {
    resetModal();
  }
  const handleShow = () => setShow(true);
  
  const processSheetData = (excelData) => {
    const wsname = excelData.SheetNames[0];
    const ws = excelData.Sheets[wsname];

    const dataParse = XLSX.utils.sheet_to_json(ws, {header: 1});
    let csv = [];
    let headers = [];
    for (let i = 0; i < dataParse.length; i++) {
      if (dataParse[i] === "") continue;
      let fields = dataParse[i];
      if (i === 0) {
        headers = fields;
      } else {
        let csvRow = [];
        for (let field of fields) {
          if (!isNaN(field))
            field = Number(field);
          csvRow.push(field);
        }
        csv.push(csvRow);
      }
    }
    setHeaders(headers);
    setRows(csv);
  }
  
  const handleFileUpload = (event) => {
    let file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = function (e) {
      const data = e.target.result;
      let readedData = XLSX.read(data, {type: 'binary'});

      processSheetData(readedData);
    };
    reader.readAsBinaryString(file);
  };

  const resetModal = () => {
    setShow(false);
    setHeaders([]);
    setRows([]);
  }

  function SendDataToFirebase(item, i){
    const date = new Date()
    const dateString = (date.getTime()).toString()
    const itemid =  dateString + i.toString()
    const ref = firebase.firestore().collection("items").doc(itemid)
    ref.set({
        itemid : itemid,
        itemname: item['name'],
        itemunit : item['unit'],
        itemrate : item['rate'],
        itemmin: item['min'],
        itemmax: item['max']
    })

  }
 function handleSubmit () {
  setShowProcessingPart(true)
    const formattedValues = rows.map((row) => {
     
    
        return {
      name : row[0] != undefined ?row[0].toString().trim():"",
      unit : row[1] != undefined ? row[1].toString():"",
      rate : row[2] != undefined ? parseInt(row[2]).toString():"",
      min:  row[3] != undefined ? parseInt(row[3]).toString():"",
      max:  row[4] != undefined ? parseInt(row[4]).toString():"",
      
    
     
      }
     
    });
   

    for(let i=0; i< formattedValues.length; i++){
        setTimeout(()=>{
          setCompletedPart((i/formattedValues.length)*100)
            SendDataToFirebase(formattedValues[i],i)

            if(i== (formattedValues.length-1)){
              setModalOpen(false)
            }
        },500)
      
     
    }

 
}

  return (
    <>
   
    {showProcessingPart ? <> 
    <div style={{ overflow:"hidden"}}>
      <div style={{marginTop:"1vh", marginRight:"2vw", marginLeft:"2vw"}}>
        <center>
        <LoaderUi/>

      </center>
      <br/>
      <Progress  color="red"  completed={completedPart} /> <br/>
      <center><h2 style={{color:"red"}}>Please don't close/ refresh the tab  until the process completes !</h2></center>
      </div>
      </div></> :
    <>

    
            
        <Navbar bg="light" expand="lg">
        <Container>
      
        
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
            </Nav>
        {rows.length == 0 ?    <Button  onClick={handleShow} className="btn btn-primary"style={{padding:"5px", margin:"5px", fontWeight:"400", cursor:"pointer"}}>Import Excel</Button> :""} 
          </Navbar.Collapse>
        </Container>
        <Modal.Header >
           {rows.length==0 ? <Modal.Title>Import a CSV or XLSX</Modal.Title> :""} 
           {/* {rows.length>0 ?  <Button variant="secondary" onClick={handleClose} className="btn btn-primary"style={{padding:"5px", margin:"5px", fontWeight:"400", cursor:"pointer"}}>
              Cancel
            </Button> :""} */}
            
          </Modal.Header>
      </Navbar>
      <Container style={{overflowY:"scroll"}}>
        <Modal show={show} onHide={handleClose}  style={{overflowY:"scroll", height:"80vh", width:"100%"}}>
       <Button variant="secondary" onClick={handleClose} className="btn btn-primary"style={{padding:"5px", margin:"5px", fontWeight:"400", cursor:"pointer"}}>
              Cancel
            </Button>
            {rows.length>0 ?
            <Button variant="primary" onClick={handleSubmit} className="btn btn-primary"style={{padding:"5px", margin:"5px", fontWeight:"400", cursor:"pointer"}}>
              Submit
            </Button>:""}
          <Modal.Body>
            {headers.length < 1 ||  rows.length < 1 ?
              <Form.Group>
                <Form.File id="file-upload" onChange={event => handleFileUpload(event) } />
              </Form.Group>
              :
              <Table striped bordered hover style={{overflowY:"scroll"}}>
                <thead>
                  <tr>
                    <th>#</th>
                    {headers.map((item, index) => (
                      <th key={index}>
                        {item}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {rows.length && rows.map((itemArray, itemIndex) => (
                    <tr key={itemIndex}>
                      <td>{itemIndex+1}</td>
                      {itemArray.map((item, index) => (
                        <td key={index}>
                          {item}
                        </td>
                      ))}
                    </tr>))
                  }
                </tbody>
              </Table>
            }
          </Modal.Body>
          {/* <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleSubmit}>
              Save Changes
            </Button>
          </Modal.Footer> */}
        </Modal>
        <Table striped bordered hover>
         
          <tbody>
          {users.length < 1 &&
            <tr>
              {/* <td colSpan={3}>No users found.</td> */}
            </tr>
          }
          {users && users.map((user, index) => (
            <tr key={index}>
              <td>{index+1}</td>
              <td>{user.name}</td>
              <td>{user.age1}</td>
            </tr>
          ))}
          </tbody>
        </Table>
      </Container>
      </>}
   
    </>

  );
}

export default ImportItems;