import React, { Fragment, useEffect, useState, useContext } from "react";
import * as Loader from "react-loader-spinner";
import { logo } from "./Common/CommonVarFunc";

 export default function LoaderUi(){

    return(<>
   
   <div className="d-flex justify-content-center"  >
        <div className="d-flex p-2 col-example" >
        <img  src={logo} width="200px" />
        
          </div></div>
          <div className="d-flex justify-content-center"  >
        <div className="d-flex p-2 col-example" >
          

        <h1> Processing Your Request</h1>  <br/>
   
          </div></div>


         
    </>
    )
}
