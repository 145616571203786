import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import { AuthProvider } from './auth/Auth';
import PrivateRoute from "./PrivateRouter"
import Login from './auth/Login';
import ImportItems from './Items/ImportItems';
import CheckInvoicing from './Invoicing/CheckInvoicing';
import ItemsList from './Items/ItemsList';
import InvoicingList from './Invoicing/InvoicingList';
import InvoicePrint from './Invoicing/InvoicePrint';


function App() {

  return (
    <BrowserRouter>
    <AuthProvider>
     
        <div>
          <PrivateRoute exact path="/"    component={ItemsList}   />  
          <PrivateRoute exact path="/invoicing"    component={InvoicingList}   />  
          <PrivateRoute exact path="/items"    component={ItemsList}   />  
          <PrivateRoute exact path="/in-print/:id"    component={InvoicePrint}   />  
          <Route exact path="/login" component={Login} />
          
        </div>
        </AuthProvider>
      </BrowserRouter>
    
  
     
      
  
  );
}

export default App;