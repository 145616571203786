import {Navbar, Nav, Form, Button, Table, Container} from 'react-bootstrap';
import '../App.css';
import React from 'react'
import { useState, useEffect,useContext } from 'react';
 import firebase from '../firebase';
import { shuffle } from '../Common/CommonVarFunc';
import * as XLSX from "xlsx";
import Progress from "react-progressbar";
import LoaderUi from "../LoaderUi"
import { MDBDataTable } from 'mdbreact';
import '@fortawesome/fontawesome-free/css/all.min.css';
import'bootstrap-css-only/css/bootstrap.min.css'; 
import 'mdbreact/dist/css/mdb.css';
import Modal from '../Modal/Modal';
import { useHistory } from 'react-router-dom';
function ItemsList() {

 
    const [itemsArray, setItemsArray ] = useState([])
    const [modalOpen, setModalOpen] = useState(false)
    const [itemFile, setItemFile] = useState("")
    const history = useHistory()
    useEffect(()=>{
        const ref  = firebase.firestore().collection("items").orderBy('itemname')
        ref.onSnapshot((querySnapshot)=>{
            let temp=[]
          
            querySnapshot.forEach((doc)=>{
              
                    temp.push(doc.data())
                
            })
           
           setItemsArray(temp)
            
        })
    },[])

    useEffect(()=>{
      const ref = firebase.firestore().collection("template").doc("document")

      ref.get().then((doc)=>{
        if(doc.exists){
          setItemFile(doc.data().itemslist)
        }
      })
    },[])


    const data = {
        columns: [
        
          {
            label: 'Name',
            field: 'itemname',
            sort: 'asc',
            width: 150
          },
          {
            label: 'Rate',
            field: 'itemrate',
            sort: 'asc',
            width: 150
          },
        
          {
            label: 'Min Unit',
            field: 'itemmin',
            sort: 'asc',
            width: 150
          },
          {
            label: 'Max Unit',
            field: 'itemmax',
            sort: 'asc',
            width: 150
          },         
        ],
        rows: 
         
        
        JSON.parse(JSON.stringify(itemsArray))
      
      };
    

  return (
   <>
   {modalOpen && (<Modal setOpenModal={setModalOpen}/>)}
   <button onClick={()=> firebase.auth().signOut()} className="btn btn-primary" style={{float:"right", margin:"10px", cursor:"pointer"}}>Sign Out</button>
   <button  className="btn btn-primary" onClick={()=> history.push("/invoicing")}style={{float:"right", margin:"10px", cursor:"pointer"}} >Invoices</button>
   <a href={itemFile} ><button  className="btn btn-primary" style={{float:"right", margin:"10px", cursor:"pointer"}} >Items Template</button></a>

 <br/>
  <h2>ITEMS LIST</h2>
  <button className="btn btn-primary" style={{float:"right", margin:"10px", cursor:"pointer"}} onClick={()=>setModalOpen(true)}>Import Items</button> <br/>

 <MDBDataTable
      striped
      bordered
      small
      data={data}
     
    />
    </>



  );
}

export default ItemsList;