import {Navbar, Nav, Form, Button, Table, Container, Modal} from 'react-bootstrap';
import '../App.css';
import React from 'react'
import { useState, useEffect,useContext } from 'react';
 import firebase from '../firebase';
import { randomIntFromInterval, shuffle,  GetUnitsFromInvoiceArray,  GetPriceFromInvoiceArray } from '../Common/CommonVarFunc';
import * as XLSX from "xlsx";
import Progress from "react-progressbar";
import LoaderUi from "../LoaderUi"
import { ExcelDateToJSDate } from '../Common/CommonVarFunc';

function CheckInvoicing({setModalOpen}) {

 
    const [users, setUsers] = useState([]);
    const [itemsPieceArray, setItemsPieceArray ] = useState([])
    const [itemsKgArray, setItemsKgArray] = useState([])
    const [show, setShow] = useState(false);
  const [headers, setHeaders] = useState([]);
  const [rows, setRows] = useState([]);
  const [completedPart, setCompletedPart] = useState(0)
  const [showProcessingPart, setShowProcessingPart] = useState(false)
  const [totalInvoices, setTotalInvoices] = useState("")
  const [sentInvoices, setSentInvoices] = useState("")
  const [count, setCount] = useState(0)
  useEffect(()=>{
    const ref = firebase.firestore().collection("invoices")
    ref.get().then((doc)=>setCount(doc.size))
  },[])
    useEffect(()=>{
        const ref  = firebase.firestore().collection("items")
        ref.onSnapshot((querySnapshot)=>{
            let temp=[]
            let temp1=[]
            querySnapshot.forEach((doc)=>{
                if(doc.data().itemunit.toLowerCase() == "piece"){
                    temp.push(doc.data())
                }else{
                    temp1.push(doc.data())
                }
            })
         
            setItemsKgArray(shuffle(temp1))
            setItemsPieceArray(shuffle(temp))
        })
    },[])




    function GetFinalUnits(unit, rate, amount){
       
        if((unit*rate)<=amount){
            return unit;
        }

        else{
            
            while((unit*rate)>= amount){
                unit = unit-1
                if((unit*rate) <= amount){
                    return unit
                }
             
            }
        }
    }

    function GetFinalUnitsBufferCheck(unit, rate, amount, buffer){
       
      if((unit*rate)<=amount){
          return unit;
      }

      else{
          
          while((unit*rate)>= amount){
              unit = unit-1
              if((unit*rate) <= amount){
                  return unit
              }
           
          }
      }
  }

  function Submit(x, counter){
    if(x != undefined){
      let tempItemsInInvoice = []
      let totalAmount = parseInt(x['invoiceAmount'])
      let  randomCount = 10
     let x1 = shuffle(itemsPieceArray)
     let x2 = shuffle(itemsKgArray)
      if(totalAmount>1000){
          randomCount = 100
      }
      let arrayFlag = 10;
      if(x1.length <10){
        arrayFlag = x1.length - 1
      }
      for(let i=1; i<=arrayFlag; i++){
          let x ={}
          x= x1[i]
          let units =0
          let tempRate = parseInt(x1[i]['itemrate'])
          if(tempRate<= totalAmount){
              let probableUnits = randomIntFromInterval(parseInt(x1[i]['itemmin']), parseInt(x1[i]['itemmax']))
              console.log("JJJJJJJJJJ", probableUnits)
              units = GetFinalUnits(probableUnits,tempRate, totalAmount)
              console.log(units)
              if(units != 0){
                  x['itemprice'] = (units*tempRate).toFixed(2)
                  x['itemquantity'] = units.toFixed(2)
                  totalAmount = totalAmount-(units*tempRate)
                  tempItemsInInvoice.push(x)
              }
            
          }
      }
  
      if(totalAmount >0){
      
      
     
        for(let i=1; i<x2.length; i++){
      
          let x ={}
          x= x2[i]
          let units =0
          let tempRate = parseInt(x2[i]['itemrate'])
          if( totalAmount > 0 ){
    
              let probableUnits = randomIntFromInterval(parseInt(x2[i]['itemmin']), parseInt(x2[i]['itemmax']))
              console.log(probableUnits)
              units = GetFinalUnits(probableUnits,tempRate, totalAmount)
              
              if(units != 0){
                  x['itemprice'] = (units*tempRate).toFixed(2)
                  x['itemquantity'] = units.toFixed(2)
                  totalAmount = totalAmount-(units*tempRate)
                  tempItemsInInvoice.push(x)
              }

             
            
          }
      }
  
  
      }

      while(totalAmount >0){
          for(let i=0; i< x2.length; i++){
            x2 = shuffle(x2)
            if(totalAmount >0){
             
              let xy = x2[i]
              let units =0
              let tempRate = parseInt(x2[i]['itemrate'])
              let tempMax =   parseInt(x2[i]['itemmax'])
              let existingUnits = GetUnitsFromInvoiceArray(tempItemsInInvoice, x2[i]['itemid'])
              let existingPrice = GetPriceFromInvoiceArray(tempItemsInInvoice, x2[i]['itemid'])
           
              let bufferUnits = tempMax - existingUnits
         
              if(bufferUnits >0){
                let probableUnits = totalAmount/tempRate
                if(probableUnits > bufferUnits){
                  units = bufferUnits
                }else{
                  units = probableUnits
                }
              // let units = GetFinalUnits(probableUnits,tempRate, totalAmount)
                  
                if(units != 0){
                    xy['itemprice'] = (existingPrice+(units*tempRate)).toFixed(2)
                    xy['itemquantity'] = (existingUnits+units).toFixed(2)
                    totalAmount = totalAmount-(units*tempRate)
                  
                    tempItemsInInvoice.push(xy)
                   
                    // let a = UpdateUnits(tempItemsInInvoice, xy)
                    // if(a[1] != -1){
                    //   tempItemsInInvoice.splice(a[1],1)
                    //   tempItemsInInvoice.push(a[0])
                    // }
                    
                    // else{
                    //   tempItemsInInvoice.push(a[0])
                    // }
                    
                }
              }
              
            }
           
          }
      }
    
      var clean = tempItemsInInvoice.filter((arr, index, self) =>
      index === self.findIndex((t) => (t.itemprice === arr.itemprice && t.itemquantity === arr.itemquantity)))
      
  
   
      SendDataToFirebase(x,clean,counter)
  

    }
     }
  function SendDataToFirebase(partydata,itemsArray, i){

    const date = new Date()
    const dateString = (date.getTime()).toString()
    const invoiceCount = count +i+1;
    
  //  const invoiceid =  dateString + i.toString()
  let invoiceid = MakeInvoiceIds(invoiceCount.toString())

    const ref1 = firebase.firestore().collection("invoices").doc(invoiceid)
    const ref = firebase.firestore().collection("invoices").doc(invoiceid).collection("invoiceitems")
    ref1.set({
        partyName : partydata.partyname,
        partyaddress: partydata.partyaddress,
        partymobile : partydata.partymobile,
        partyemail : partydata.partyemail ,
        invoiceid: invoiceid,
        datetime : date.toString(),
        invoiceamount : partydata.invoiceAmount,
        paymentmode : partydata.paymentmode,
        invoicedate :  partydata.invoicedate
    })

    for(let i=0; i< itemsArray.length; i++){
        
 
            ref.doc(`items${i.toString()}`).set(itemsArray[i])

      
    }
    

  }

  Date.prototype.toShortFormat =  function() {

    
    let day = this.getDate();
    
    let monthIndex = this.getMonth() +1;
    //let monthName = monthNames[monthIndex];
    
    let year = this.getFullYear();
    
    return `${day}-${monthIndex}-${year}`;  
  }
  function handleSubmit () {
    setShowProcessingPart(true)
      const formattedValues = rows.map((row) => {
       
        if(row[4] != undefined && row[4] !=""){
          return {
            partyname : row[0] != undefined ?row[0].toString().trim():"",
            partyaddress : row[1] != undefined ? row[1].toString():"",
            partymobile : row[2] != undefined ? row[2].toString():"",
            partyemail :  row[3] != undefined ? row[3].toString():"",
            invoiceAmount :  row[4] != undefined ? Math.floor(row[4]).toString():"",
            paymentmode : row[5] != undefined ? row[5].toString() :"",
            invoicedate : row[6] != undefined ?  row[6].toString() :""
            }
        }
      
        
       
      });
     
      setTotalInvoices(formattedValues.length)
      for(let i=0; i< formattedValues.length; i++){
          setTimeout(()=>{
              Submit(formattedValues[i],i)
              setCompletedPart(((i+1)/formattedValues.length)*100)
              setSentInvoices((i+1).toString())

              if(i== (formattedValues.length-1)){
                  window.alert("All Invoices Generated !!")
                  setShowProcessingPart(false)
                  setModalOpen(false)
                  resetModal()
              }
              
          },5000*i)
        
       
      }
  
   
  }

  const handleClose = () => {
    resetModal();
  }
  const handleShow = () => setShow(true);
  
  const processSheetData = (excelData) => {
    const wsname = excelData.SheetNames[0];
    const ws = excelData.Sheets[wsname];

    const dataParse = XLSX.utils.sheet_to_json(ws, {header: 1});
    let csv = [];
    let headers = [];
    for (let i = 0; i < dataParse.length; i++) {
      if (dataParse[i] === "") continue;
      let fields = dataParse[i];
      if (i === 0) {
        headers = fields;
      } else {
        let csvRow = [];
        for (let field of fields) {
          if (!isNaN(field))
            field = Number(field);
          csvRow.push(field);
        }
        csv.push(csvRow);
      }
    }
    setHeaders(headers);
    setRows(csv);
  }
  function MakeInvoiceIds(invoiceid){
    if(invoiceid.length == 1){
      return `00000000${invoiceid}`
    }
    if(invoiceid.length == 2){
      return `0000000${invoiceid}`
    }  
    if(invoiceid.length == 3){
      return `000000${invoiceid}`
    }  
    if(invoiceid.length == 4){
      return `00000${invoiceid}`
    }  
    if(invoiceid.length == 5){
      return `0000${invoiceid}`
    }  
    
    if(invoiceid.length == 6){
      return `000${invoiceid}`
    }  
    if(invoiceid.length == 7){
      return `00${invoiceid}`
    }  
    if(invoiceid.length == 8){
      return `0${invoiceid}`
    } 
    if(invoiceid.length == 9){
      return `${invoiceid}`
    } 
     
  }
  const handleFileUpload = (event) => {
    let file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = function (e) {
      const data = e.target.result;
      let readedData = XLSX.read(data, {type: 'binary'});

      processSheetData(readedData);
    };
    reader.readAsBinaryString(file);
  };

  const resetModal = () => {
    setShow(false);
    setHeaders([]);
    setRows([]);
  }


  return (
   <>
 
    {showProcessingPart ? <> 
    <div style={{ overflow:"hidden"}}>
      <div style={{marginTop:"1vh", marginRight:"2vw", marginLeft:"2vw"}}>
        <center>
        <LoaderUi/>
 </center>
      <br/>
      <Progress  color="red"  completed={completedPart} /> <br/>
      <center><h2 style={{color:"red"}}>Each Invoice may take upto 5 seconds to Generate !</h2></center>
      <center><h2 style={{color:"red"}}>Please don't close/ refresh the tab  until the process completes !</h2></center>
      </div>
      </div></> :
    <>
    
            
        <Navbar bg="light" expand="lg">
        <Container>
      
        
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
            </Nav>
        {rows.length == 0 ?    <Button  onClick={handleShow} className="btn btn-primary"style={{padding:"5px", margin:"5px", fontWeight:"400", cursor:"pointer"}}>Import Excel</Button> :""} 
          </Navbar.Collapse>
        </Container>
        <Modal.Header >
           {rows.length==0 ? <Modal.Title>Import a CSV or XLSX</Modal.Title> :""} 
           {/* {rows.length>0 ?  <Button variant="secondary" onClick={handleClose} className="btn btn-primary"style={{padding:"5px", margin:"5px", fontWeight:"400", cursor:"pointer"}}>
              Cancel
            </Button> :""} */}
            
          </Modal.Header>
      </Navbar>
      <Container style={{overflowY:"scroll"}}>
        <Modal show={show} onHide={handleClose}  style={{overflowY:"scroll", height:"90vh", Width:"200% !important"}}>
       <Button variant="secondary" onClick={handleClose} className="btn btn-primary"style={{padding:"5px", margin:"5px", fontWeight:"400", cursor:"pointer"}}>
              Cancel
            </Button>
            {rows.length>0 ?
            <Button variant="primary" onClick={handleSubmit} className="btn btn-primary"style={{padding:"5px", margin:"5px", fontWeight:"400", cursor:"pointer"}}>
              Save Change
            </Button>:""}
          <Modal.Body>
            {headers.length < 1 ||  rows.length < 1 ?
              <Form.Group>
                <Form.File id="file-upload" onChange={event => handleFileUpload(event) } />
              </Form.Group>
              :
              <Table striped bordered hover style={{overflowY:"scroll", overflowX: "scroll"}}>
                <thead>
                  <tr>
                    <th>#</th>
                    {headers.map((item, index) => (
                      <th key={index}>
                        {item}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {rows.length && rows.map((itemArray, itemIndex) => (
                    <tr key={itemIndex}>
                      <td>{itemIndex+1}</td>
                      {itemArray.map((item, index) => (
                        <td key={index}>
                          {item}
                        </td>
                      ))}
                    </tr>))
                  }
                </tbody>
              </Table>
            }
          </Modal.Body>
          {/* <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleSubmit}>
              Save Changes
            </Button>
          </Modal.Footer> */}
        </Modal>
        <Table striped bordered hover>
         
          <tbody>
          {users.length < 1 &&
            <tr>
              {/* <td colSpan={3}>No users found.</td> */}
            </tr>
          }
          {users && users.map((user, index) => (
            <tr key={index}>
              <td>{index+1}</td>
              <td>{user.name}</td>
              <td>{user.age1}</td>
            </tr>
          ))}
          </tbody>
        </Table>
      </Container>
      </>}
   
    </>



  );
}

export default CheckInvoicing;