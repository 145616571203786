import {Navbar, Nav, Form, Button, Table, Container} from 'react-bootstrap';
import '../App.css';
import React from 'react'
import { useState, useEffect,useContext } from 'react';
 import firebase from '../firebase';

import { MDBDataTable } from 'mdbreact';
import '@fortawesome/fontawesome-free/css/all.min.css';
import'bootstrap-css-only/css/bootstrap.min.css'; 
import 'mdbreact/dist/css/mdb.css';
import Modal from '../Modal/Modal';
import { useHistory } from 'react-router-dom';
import InvoiceModal from '../Modal/InvoiceModal';
function InvoicingList() {

 
    const [invoiceArray, setInvoiceArray ] = useState([])
    const [modalOpen, setModalOpen] = useState(false)
    const[invoicesforRender,setInvoicesForRender] = useState([])
    const [invoiceFile, setInvoiceFile] = useState("")
    const history = useHistory()
    useEffect(()=>{
        const ref  = firebase.firestore().collection("invoices").orderBy('invoiceid')
        ref.onSnapshot((querySnapshot)=>{
            let temp=[]
          
            querySnapshot.forEach((doc)=>{
            
                    temp.push(doc.data())
                
            })
           
           setInvoiceArray(temp)
            
        })
    },[])

    useEffect(()=>{
      const ref = firebase.firestore().collection("template").doc("document")

      ref.get().then((doc)=>{
        if(doc.exists){
          setInvoiceFile(doc.data().invoicedata)
        }
      })
    },[])

    useEffect(()=>{
 
    let postsArray = JSON.parse(JSON.stringify(invoiceArray));
    let userData = [];
    postsArray.map((item, index) => {
      item.id = (
        <div style={{ fontWeight: "bold", fontSize: "1.2em" }}>{item.invoiceid}</div>
      );
      item.action = (
          <>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            className="uil-trash-alt"
            style={{
              cursor: "pointer",
              color: "white",
              fontSize: ".7em",
              padding: ".5rem",
              borderRadius: ".3rem",
              background: "green",
            }}
             onClick={() => window.open(`/in-print/${invoiceArray[index].invoiceid}`, "_blank")}
          >
            Download
          </div>
        </div>
<br/>
<div style={{ display: "flex", justifyContent: "space-between" }}>
<div
  className="uil-trash-alt"
  style={{
    cursor: "pointer",
    color: "white",
    fontSize: ".7em",
    padding: ".5rem",
    borderRadius: ".3rem",
    background: (invoiceArray[index].cancelled == undefined || invoiceArray[index].cancelled !="yes" ) ? "Green" :"Red",
  }}
   onClick={()=>CanceInvoice(invoiceArray[index].invoiceid, invoiceArray[index].cancelled)}
>
 {(invoiceArray[index].cancelled == undefined || invoiceArray[index].cancelled !="yes" ) ? "Cancel Invoice" :"Uncancel Invoice"}
</div>
</div>
</>
      );
      userData.push(item);
    });
    setInvoicesForRender(userData);
  }, [invoiceArray]);


    const data = {
        columns: [
           
            {
                label: 'Invoice Number',
                field: 'invoiceid',
                sort: 'asc',
                width: 150,
                
              },{
                label: 'Invoice Date',
                field: 'invoicedate',
                sort: 'asc',
                width: 150,
              },
        
          {
            label: 'Party Name',
            field: 'partyName',
            sort: 'asc',
            width: 150
          },
          {
            label: 'Party Address',
            field: 'partyaddress',
            sort: 'asc',
            width: 150
          },
          {
            label: 'Party Mobile',
            field: 'partymobile',
            sort: 'asc',
            width: 150,
        
          },
     
          {
            label: 'Invoice Amount',
            field: 'invoiceamount',
            sort: 'asc',
            width: 150
          }, {
            label: 'Mode of Payment',
            field: 'paymentmode',
            sort: 'asc',
            width: 150
          },  {
            label: "Action",
            field: "action",
            width: 100,
          },
        ],
        rows: invoicesforRender

      };
    
      function CanceInvoice(invoiceid, status){
        const ref = firebase.firestore().collection("invoices").doc(invoiceid)
        if(status == "yes"){
            ref.update({
                cancelled:"no"
            }).then(()=>{
                window.alert(` Invoice No. :- ${invoiceid} Uncancelled  !!`)
            })
        }
        else{
           
            ref.update({
                cancelled : "yes"
            }).then(()=>{
                window.alert(`Invoice No. :- ${invoiceid} Cancelled !!`)
            })
        }
       
        
      }

      function DownloadAllInvoices(){
          for(let i=0; i< invoiceArray.length; i++){
            window.open(`/in-print/${invoiceArray[i].invoiceid}`)
          }
      }

  return (
   <>
   {modalOpen && (<InvoiceModal setOpenModal={setModalOpen}/>)}

   <button onClick={()=> firebase.auth().signOut()} className="btn btn-primary" style={{float:"right", margin:"10px", cursor:"pointer"}}>Sign Out</button>
   <button  className="btn btn-primary" onClick={()=> history.push("/")}style={{float:"right", margin:"10px", cursor:"pointer"}} >Items</button>
   <a href={invoiceFile} ><button  className="btn btn-primary"style={{float:"right", margin:"10px", cursor:"pointer"}}>Invoice Template</button></a>
 <br/>
  <h2>INVOICES LIST</h2>
  <button className="btn btn-primary" style={{float:"right", margin:"10px", cursor:"pointer"}} onClick={()=>setModalOpen(true)}>Generate Invoices</button>
  <button className="btn btn-primary" style={{float:"right", margin:"10px", cursor:"pointer"}} onClick={DownloadAllInvoices}>Download All Invoices</button>  <br/><br/>
  
 <MDBDataTable
      striped
      bordered
      small
      data={data}
     
    />
    </>



  );
}

export default InvoicingList;