import React, { useEffect, useState } from "react"
import "./InvoicePrint.css"
import firebase from "../firebase"
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import * as htmlToImage from 'html-to-image';
import { cancelImage, toTitleCase } from "../Common/CommonVarFunc";
import Cancelled from "./cancelled.png"
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';
export default function InvoicePrint(){
    let temp = window.location.href
    let arr = temp.split("/")
    let invoiceid = arr[arr.length-1]
    const [info, setInfo] = useState({})
    const [items, setItems] = useState([])
    const [vendorInfo, setVendorInfo] = useState({})
useEffect(()=>{
    const ref = firebase.firestore().collection("invoices").doc(invoiceid)
    ref.get().then((doc)=>{
        
        setInfo(doc.data())
    } )

    const ref1 = ref.collection("invoiceitems")
    ref1.onSnapshot((querysnapshot)=>{
        let temp=[]
        querysnapshot.forEach((doc)=>{
            temp.push(doc.data())
        })
        setItems(temp)
    }) 
    const ref2 = firebase.firestore().collection("vendors").doc("vendordetails")
    ref2.get().then((doc)=>{
        setVendorInfo(doc.data())
    })
},[])

const generatepdf = (id) => {
    const input = document.querySelector('#divToPrint');
    html2canvas(input,{useCORS:true})
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png',0.98);
        const pdf = new jsPDF();
        const imgProps= pdf.getImageProperties(imgData);
        var width = pdf.internal.pageSize.getWidth()
        var height =  (imgProps.height * width) / imgProps.width;
        pdf.addImage(imgData, 'PNG', 0,0, width,height );
        
        pdf.save(`download${id}.pdf`);
       
       
       // window.close()
      
      })
  };
useEffect(()=>{
    if(Object.keys(info).length !== 0 && items.length >0 && Object.keys(vendorInfo).length !== 0)
    {
        generatepdf(info['invoiceid'])
    }
},[items, info, vendorInfo])
    return(
        <>
        <div className="container" id="divToPrint">
    <div className="card">
        <div className="card-body">
            <div id="invoice">
                <div className="toolbar hidden-print">
                    <div className="text-end">
                        {/* <button type="button" className="btn btn-dark"><i className="fa fa-print"></i> Print</button>
                        <button type="button" className="btn btn-danger"><i className="fa fa-file-pdf-o"></i> Export as PDF</button> */}
                    </div>
                    <hr/>
                </div>
    
                <div className="invoice overflow-auto">
                    <div style={{minWidth: "600px"}}>
               <header>
                            <div className="row">
                                <div className="col">
                                    <a href="javascript:;">
    										{info['cancelled'] == "yes" ?	
                                           <img src={Cancelled} width="150px" />
                                   :""} </a>
                                </div>
                                <div className="col company-details">
                                    <h2 className="name">
                                        <a target="_blank">
									{vendorInfo['vendorname']}
									</a>
                                    </h2>
                                    <div>{vendorInfo['vendoraddress']}</div>
                                    <div>{vendorInfo['vendormobile']}</div>
                                    <div>{vendorInfo['vendoremail']}</div>
                                </div>
                            </div>
                        </header>
                        <main>
                            <div className="row contacts">
                                <div className="col invoice-to">
                                    <div className="text-gray-light">INVOICE TO:</div>
                                    <h2 className="to">{info['partyName']}</h2>
                                    <div className="address">{info['partyaddress']}</div>
                                    <div className="email"><a>{info['partyemail']}</a>
                                    </div>
                                </div>
                                <div className="col invoice-details">
                                    <h1 className="invoice-id">#{info['invoiceid']}</h1>
                                    <div className="date">Date of Invoice: {info['invoicedate']}</div>
                                    {/* <div className="date">Due Date: {info['invoicedate']}</div> */}
                                </div>
                            </div>
                            <table>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th className="text-left">DESCRIPTION</th>
                                        <th className="text-right">Rate/unit</th>
                                        <th className="text-right">QUANTITY</th>
                                        <th className="text-right">TOTAL</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {items.map((doc,key)=>(<>
                                    <tr>
                                        <td className="no">{key+1}</td>
                                        <td className="text-left">
                                            <h3>
                                            
                                            {toTitleCase(doc.itemname)}
										
                                            </h3>
                                          
										   </td>
                                        <td className="unit">₹ {doc.itemrate} / {doc.itemunit}</td>
                                        <td className="qty">{doc.itemquantity}</td>
                                        <td className="total">₹ {doc.itemprice} </td>
                                    </tr>
                                    </>))}
                                    {/* <tr>
                                        <td className="no">01</td>
                                        <td className="text-left">
                                            <h3>Website Design</h3>Creating a recognizable design solution based on the company's existing visual identity</td>
                                        <td className="unit">₹40.00</td>
                                        <td className="qty">30</td>
                                        <td className="total">₹1,200.00</td>
                                    </tr>
                                    <tr>
                                        <td className="no">02</td>
                                        <td className="text-left">
                                            <h3>Website Development</h3>Developing a Content Management System-based Website</td>
                                        <td className="unit">₹40.00</td>
                                        <td className="qty">80</td>
                                        <td className="total">₹3,200.00</td>
                                    </tr>
                                    <tr>
                                        <td className="no">03</td>
                                        <td className="text-left">
                                            <h3>Search Engines Optimization</h3>Optimize the site for search engines (SEO)</td>
                                        <td className="unit">₹40.00</td>
                                        <td className="qty">20</td>
                                        <td className="total">₹800.00</td>
                                    </tr> */}
                                </tbody>
                                <tfoot>
                                    {/* <tr>
                                        <td colspan="2"></td>
                                        <td colspan="2">SUBTOTAL</td>
                                        <td>₹5,200.00</td>
                                    </tr> */}
                                    {/* <tr>
                                        <td colspan="2"></td>
                                        <td colspan="2">TAX 25%</td>
                                        <td>₹1,300.00</td>
                                    </tr> */}
                                    <tr>
                                        <td colspan="2"></td>
                                        <td colspan="2">GRAND TOTAL</td>
                                        <td>₹ {info['invoiceamount']}</td>
                                    </tr>
                                </tfoot>
                            </table>
                            {/* <div className="thanks">Thank you!</div> */}
                            <div className="notices">
                                <div>MODE OF PAYMENT</div>
                                <div className="notice">{info['paymentmode']}</div>
                            </div>
                        </main>
                        <footer>Invoice was created on a computer and is valid without the signature and seal.</footer>
                    </div>
                    {/* <!--DO NOT DELETE THIS div. IT is responsible for showing footer always at the bottom--> */}
                    <div></div>
                </div>
            </div>
        </div>
    </div>
</div>
</>
    )
}