import React from "react";
import ImportItems from "../Items/ImportItems";
import "./Modal.css";

function Modal({ setOpenModal}) {
  return (
    <div className="modalBackground">
      <div className="modalContainer" style={{maxWidth:"auto !important"}}>
        <div className="titleCloseBtn">
          <button
            onClick={() => 
                setOpenModal(false)
            }
          >
            X
          </button>
        </div>
        <div className="title" >
          <h2>Import Items</h2>

        </div>
        <div className="body">
            <ImportItems setModalOpen={setOpenModal}/>
        </div>
        <div className="footer">
          <button
            onClick={() => {
              setOpenModal(false);
            }}
         
          >
          Okay
          </button>
      
        </div>
      </div>
    </div>
  );
}

export default Modal;